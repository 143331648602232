import Pathname from "../../app/naming/Pathname";
import HojinIcon from "@material-ui/icons/Domain";
import JigyoshoIcon from "@material-ui/icons/Store";
import AgentCollectionIcon from "@material-ui/icons/DirectionsRun";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import JobOfferIcon from "@material-ui/icons/Person";
import CareStaffIcon from "@material-ui/icons/PersonAdd";

const BackOffcieMenuType = {
  お知らせ: {
    name: "information",
    label: "お知らせ",
    icon: NotificationsNone,
    pathname: Pathname.backoffice_information,
    available: () => true,
  },
  法人管理: {
    name: "hojin",
    label: "法人管理",
    icon: HojinIcon,
    pathname: Pathname.backoffice_hojin,
    available: (user) => user.hasBackOfficeHojinMenu(),
  },
  事業所管理: {
    name: "jigyosho",
    label: "事業所管理",
    icon: JigyoshoIcon,
    pathname: Pathname.backoffice_jigyosho,
    available: (user) => user.hasBackOfficeJigyoshoMenu(),
  },
  代行入力: {
    name: "agent-collection",
    label: "代行入力",
    icon: AgentCollectionIcon,
    pathname: Pathname.backoffice_agent_collection,
    available: (user) => user.hasBackOfficeAgentCollectionMenu(),
  },
  求人管理: {
    name: "job-offer",
    label: "求人管理",
    icon: JobOfferIcon,
    pathname: Pathname.backoffice_job_offer,
    available: (user) => user.isJobOfferEnabled,
  },
  ニュース管理: {
    name: "news",
    label: "ニュース管理",
    icon: NotificationsNone,
    pathname: Pathname.backoffice_news,
    available: (user) => user.hasBackOfficeHojinMenu(),
  },
  バナー管理: {
    name: "banner",
    label: "バナー管理",
    icon: NotificationsNone,
    pathname: Pathname.backoffice_banner,
    available: (user) => user.hasBackOfficeBannerMenu(),
  },
  個人アカウント: {
    name: "care-staff",
    label: "個人アカウント",
    icon: CareStaffIcon,
    pathname: Pathname.backoffice_carestaff,
    available: (user) => user.canManageAccountCareStaff(),
  },
};

export function backOfficeMenuTypeValues() {
  return Object.keys(BackOffcieMenuType).map((key) => BackOffcieMenuType[key]);
}

export function backOfficeMenuTypeValueOf(name) {
  return backOfficeMenuTypeValues().find((type) => type.name == name);
}

export default BackOffcieMenuType;
