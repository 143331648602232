import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import * as actions from "../../../state/news/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FixedTable from "../../../app/ui/table/FixedTable";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import SmallPaddingTableCell from "../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../app/ui/layout/Right";
import RaisedSecondaryButton from "../../../app/ui/button/RaisedSecondaryButton";
import Mount from "../../../app/ui/layout/Mount";
import For from "../../../app/ui/layout/For";

class NewsMessageList extends React.Component {
  constructor(props) {
    super(props);
    // 「ニュース追加」クリックイベント
    this.handleNewsMessageAddDetailClick = this.handleNewsMessageAddDetailClick.bind(
      this
    );
    // 「タイトル」クリックイベント
    this.handleNewsMessageTitlelClick = this.handleNewsMessageTitlelClick.bind(
      this
    );
    // 「削除」クリックイベント
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
    this.handlePlusContractButtonClick = this.handlePlusContractButtonClick.bind(
      this
    );
    this.handlePlusContractDialogCloseButtonClick = this.handlePlusContractDialogCloseButtonClick.bind(
      this
    );
    this.handlePlusRoleButtonClick = this.handlePlusRoleButtonClick.bind(this);
    this.handlePlusRoleDialogCloseButtonClick = this.handlePlusRoleDialogCloseButtonClick.bind(
      this
    );
    // 「ピックアップニュース追加」クリックイベント
    this.handlePickupMessageAddDetailClick = this.handlePickupMessageAddDetailClick.bind(
      this
    );
    // 「ピックアップニュースタイトル」クリックイベント
    this.handlePickupMessageTitlelClick = this.handlePickupMessageTitlelClick.bind(
      this
    );
    // 「ピックアップニュース削除」クリックイベント
    this.handlePickupDeleteButtonClick = this.handlePickupDeleteButtonClick.bind(
      this
    );
    this.handlePickupRemoveButtonClick = this.handlePickupRemoveButtonClick.bind(
      this
    );
    this.handlePickupRemoveDialogCloseButtonClick = this.handlePickupRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handlePickupRemoveDialogRevemoButtonClick = this.handlePickupRemoveDialogRevemoButtonClick.bind(
      this
    );
  }

  state = {
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
    removePickupDialogIsOpen: false,
    removePickupTargetId: null,
    isPickupDeleteButton: false,
    plusContractDialogIsOpen: false,
    plusRoleDialogIsOpen: false,
  };

  componentDidMount() {
    const { actions } = this.props;
    const { user } = this.props.authentication;
    // ニュース一覧の取得
    if (!user.canAddHojin()) {
      actions.requestList();
    }
  }

  // 「ニュース追加」クリックイベント
  handleNewsMessageAddDetailClick() {
    const { actions } = this.props;
    const searchParams = new URLSearchParams();
    actions.gotoNewsMessageAddDetailPage(searchParams.toString());
  }

  // 「タイトル」クリックイベント
  handleNewsMessageTitlelClick(id) {
    const { actions } = this.props;
    actions.gotoNewsMessageEditDetailPage(id);
  }

  // 「削除」クリックイベント
  handleDeleteButtonClick(id) {
    const { actions } = this.props;
    actions.submitToRemove(id);
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    });
  }

  handleRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: null,
    });
  }

  handleRemoveDialogRevemoButtonClick(e) {
    e.stopPropagation();
    this.setState({ isDeleteButton: true });
    this.handleDeleteButtonClick(this.state.removeTargetId);
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: null,
    });
  }

  handlePlusContractButtonClick(e) {
    e.stopPropagation();
    this.setState({
      plusContractDialogIsOpen: true,
    });
  }

  handlePlusContractDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      plusContractDialogIsOpen: false,
    });
  }

  handlePlusRoleButtonClick(e) {
    e.stopPropagation();
    this.setState({
      plusRoleDialogIsOpen: true,
    });
  }

  handlePlusRoleDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      plusRoleDialogIsOpen: false,
    });
  }

  // 「ピックアップニュース追加」クリックイベント
  handlePickupMessageAddDetailClick() {
    const { actions } = this.props;
    const searchParams = new URLSearchParams();
    actions.gotoPickupMessageAddDetailPage(searchParams.toString());
  }
  // 「ピックアップニュースタイトル」クリックイベント
  handlePickupMessageTitlelClick(id) {
    const { actions } = this.props;
    actions.gotoPickupMessageEditDetailPage(id);
  }

  // 「ピックアップニュース削除」クリックイベント
  handlePickupDeleteButtonClick(id) {
    const { actions } = this.props;
    actions.submitPickupToRemove(id);
  }

  handlePickupRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removePickupDialogIsOpen: true,
      removePickupTargetId: id,
      isPickupDeleteButton: false,
    });
  }

  handlePickupRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removePickupDialogIsOpen: false,
      removePickupTargetId: null,
    });
  }

  handlePickupRemoveDialogRevemoButtonClick(e) {
    e.stopPropagation();
    this.setState({ isDeleteButton: true });
    this.handlePickupDeleteButtonClick(this.state.removePickupTargetId);
    this.setState({
      removePickupDialogIsOpen: false,
      removePickupTargetId: null,
    });
  }

  render() {
    const { user } = this.props.authentication;
    const contracted = user.isContracted;
    return (
      <Boundary>
        {/* NOTE: さくらライフケアのみ表示 */}
        {user.group.id === 37844 ? this.renderPickup() : null}
        <SideMargin>
          <BackOfficeHead>ニュース一覧</BackOfficeHead>
          <Mount if={user.canAddNews()}>
            <FlatPrimaryButton
              onClick={(e) =>
                user.canAddHojin()
                  ? this.handlePlusRoleButtonClick(e)
                  : contracted
                  ? this.handleNewsMessageAddDetailClick()
                  : this.handlePlusContractButtonClick(e)
              }
            >
              <AddIcon />
              ニュースを追加
            </FlatPrimaryButton>
          </Mount>
          <Fragment>
            <Paper>
              <FixedTable>
                <TableHead>
                  <TableRow>
                    <SmallPaddingTableCell style={{ width: 70 }}>
                      公開日
                    </SmallPaddingTableCell>
                    <SmallPaddingTableCell>見出し</SmallPaddingTableCell>
                    <SmallPaddingTableCell style={{ width: 100 }} />
                  </TableRow>
                </TableHead>
                {this.renderNewsList()}
              </FixedTable>
            </Paper>
          </Fragment>
          {this.renderRemoveDialog()}
          {this.renderPlusContractDialog()}
          {this.renderPlusRoleDialog()}
        </SideMargin>
      </Boundary>
    );
  }

  renderNewsList() {
    const { user } = this.props.authentication;
    const { newsForm } = this.props;
    const { list, listIsLoading } = newsForm;
    if (listIsLoading) return null;
    if (!list.length) return null;
    return (
      <TableBody>
        <For
          in={list}
          each={(it) => (
            <TableRow key={it.id}>
              <SmallPaddingTableCell>
                {it.registeredDate} {this.renderNewFor(it)}
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>{it.title}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Right>
                  <Mount if={user.canEditNews()}>
                    <IconButton
                      onClick={() => this.handleNewsMessageTitlelClick(it.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Mount>
                  <Mount if={user.canRemoveNews()}>
                    <IconButton
                      onClick={(e) => this.handleRemoveButtonClick(e, it.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Mount>
                </Right>
              </SmallPaddingTableCell>
            </TableRow>
          )}
        />
      </TableBody>
    );
  }

  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>ニュース削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ニュースを削除します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderPlusContractDialog() {
    return (
      <Dialog
        onClose={this.handlePlusContractDialogCloseButtonClick}
        open={this.state.plusContractDialogIsOpen}
      >
        <DialogTitle>お知らせ</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この機能は「ミルモネットプラス」ご契約の方のみご利用できます。
            <br />
            詳細は弊社担当者までご連絡ください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handlePlusContractDialogCloseButtonClick}
          >
            閉じる
          </FlatPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderPlusRoleDialog() {
    return (
      <Dialog
        onClose={this.handlePlusRoleDialogCloseButtonClick}
        open={this.state.plusRoleDialogIsOpen}
      >
        <DialogTitle>お知らせ</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この機能は「ミルモネットプラス」ご契約の法人アカウントのみご利用できます。
            <br />
            詳細は弊社担当者までご連絡ください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handlePlusRoleDialogCloseButtonClick}
          >
            閉じる
          </FlatPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderPickup() {
    const { user } = this.props.authentication;
    const { newsForm } = this.props;
    const { pickupList } = newsForm;
    const contracted = user.isContracted;
    const pickupAddFlg = pickupList.length == 0;
    console.log("pickupAddFlg", pickupAddFlg);
    return (
      <SideMargin>
        <BackOfficeHead>ピックアップニュース</BackOfficeHead>
        <Mount if={user.canAddNews()}>
          <FlatPrimaryButton
            onClick={(e) =>
              user.canAddHojin()
                ? this.handlePlusRoleButtonClick(e)
                : contracted
                ? this.handlePickupMessageAddDetailClick()
                : this.handlePlusContractButtonClick(e)
            }
            disabled={!pickupAddFlg}
          >
            <AddIcon />
            ピックアップニュースを追加
          </FlatPrimaryButton>
        </Mount>
        <Fragment>
          <Paper>
            <FixedTable>
              <TableHead>
                <TableRow>
                  <SmallPaddingTableCell>見出し</SmallPaddingTableCell>
                  <SmallPaddingTableCell style={{ width: 100 }} />
                </TableRow>
              </TableHead>
              {this.renderPickupList()}
            </FixedTable>
          </Paper>
        </Fragment>
        {this.renderPickupRemoveDialog()}
      </SideMargin>
    );
  }

  renderPickupList() {
    const { user } = this.props.authentication;
    const { newsForm } = this.props;
    const { pickupList, listIsLoading } = newsForm;
    if (listIsLoading) return null;
    if (!pickupList.length) return null;

    return (
      <TableBody>
        <For
          in={pickupList}
          each={(it) => (
            <TableRow key={it.id}>
              <SmallPaddingTableCell>{it.title}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Right>
                  <Mount if={user.canEditNews()}>
                    <IconButton
                      onClick={() => this.handlePickupMessageTitlelClick(it.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Mount>
                  <Mount if={user.canRemoveNews()}>
                    <IconButton
                      onClick={(e) =>
                        this.handlePickupRemoveButtonClick(e, it.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Mount>
                </Right>
              </SmallPaddingTableCell>
            </TableRow>
          )}
        />
      </TableBody>
    );
  }

  renderPickupRemoveDialog() {
    return (
      <Dialog
        onClose={this.handlePickupRemoveDialogCloseButtonClick}
        open={this.state.removePickupDialogIsOpen}
      >
        <DialogTitle>ピックアップニュース削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ピックアップニュースを削除します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handlePickupRemoveDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handlePickupRemoveDialogRevemoButtonClick}
            disabled={this.state.isPickupDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

NewsMessageList.propTypes = {
  authentication: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  newsForm: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsMessageList);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    newsForm: state.newsForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoNewsMessageAddDetailPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_news_id.replace(/:id/, "add"),
            search,
          })
        ),
      gotoNewsMessageEditDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_news_id.replace(/:id/, id),
          })
        ),
      gotoReDisplay: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_news,
          })
        ),
      gotoPickupMessageAddDetailPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_pickup_id.replace(/:id/, "add"),
            search,
          })
        ),
      gotoPickupMessageEditDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_pickup_id.replace(/:id/, id),
          })
        ),
    },
  };
}

const styles = {
  new: {
    color: "red",
  },
};
